import Vue from "vue";
import Router from "vue-router";
import store from "../store/index";
import NProgress from "nprogress";
import qs from "qs";
// Containers
const DefaultContainer = () => import("@/containers/DefaultContainer");
const DyakuyuYedynnyProstir = () =>
  import("@/containers/DyakuyuYedynnyProstir");
const DyakuyuFinexpress = () => import("@/containers/DyakuyuFinexpress");
const DyakuyuEps = () => import("@/containers/DyakuyuEps");
const DyakuyuPlatizhnyyTsentr = () =>
  import("@/containers/DyakuyuPlatizhnyyTsentr");
const YedynyyProstir = () => import("@/containers/YedynyyProstir");
const Finexpress = () => import("@/containers/Finexpress");
const Eps = () => import("@/containers/Eps");
const PlatizhnyyTsentr = () => import("@/containers/PlatizhnyyTsentr");

// Main Components. Lists
const CounterpartList = () => import("@/entity/Counterparts/CounterpartList");
const ContractList = () => import("@/entity/Contracts/ContractList");
const FinancialModelList = () =>
  import("@/entity/FinancialModels/FinancialModelList");
const UserTabs = () => import("@/entity/Users/UserTabs");
const TasksList = () => import("@/entity/Tasks/TasksList");
const CounterpartActionsList = () =>
  import("@/entity/Tasks/CounterpartActions/CounterpartList");

// Main Components. Item
const Monitoring = () => import("@/entity/Monitoring/MonitoringList");
const CardsMonitoring = () =>
  import("@/entity/CardsMonitoring/CardsMonitoringList");
const ContractsMonitoring = () =>
  import("@/entity/ContractsMonitoring/ContractsMonitoringList");
const OperationList = () => import("@/entity/Operations/OperationList");
const DeclinedPaymentList = () =>
  import("@/entity/DeclinedPayments/DeclinedPaymentList");
const Report = () => import("@/entity/Reporting/Report");
const RegulationsNbu = () => import("@/entity/RegulationsNbu/RegulationsNbu");
const Counterpart = () => import("@/entity/Counterparts/Counterpart");
const Contract = () => import("@/entity/Contracts/Contract");
const FinancialModel = () => import("@/entity/FinancialModels/FinancialModel");

// Views
const Dashboard = () => import("@/views/Dashboard");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const Logout = () => import("@/views/pages/Logout");
const Register = () => import("@/views/pages/Register");
const ForgotPassword = () => import("@/views/pages/ForgotPassword");
const ResetPassword = () => import("@/views/pages/ResetPassword");
const ResetExpiredPassword = () => import("@/views/pages/ResetExpiredPassword");
const SetPassword = () => import("@/views/pages/SetPassword");

Vue.use(Router);

const router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  stringifyQuery(query) {
    return qs.stringify(query, { arrayFormat: "comma", addQueryPrefix: true });
  },
  routes: [
    {
      path: "/",
      redirect: "/main",
      name: "Головна",
      meta: {
        label: "Головна",
        requiresAuth: true,
      },
      component: DefaultContainer,
      children: [
        {
          path: "main",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "/entity/counterpart",
          meta: {
            label: "Контрагенти",
            requiresAuth: true,
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/",
              name: "CounterpartList",
              component: CounterpartList,
            },
            {
              path: ":id",
              name: "counterpart",
              meta: {
                label: "Контрагент",
                requiresAuth: true,
              },
              component: Counterpart,
            },
          ],
        },
        {
          path: "/monitoring",
          meta: {
            label: "Поточний моніторинг",
            requiresAuth: true,
          },
          component: Monitoring,
        },
        {
          path: "/cards-monitoring",
          meta: {
            label: "Моніторинг карток",
            requiresAuth: true,
          },
          component: CardsMonitoring,
        },
        {
          path: "/contracts-monitoring",
          meta: {
            label: "Моніторинг договорів",
            requiresAuth: true,
          },
          component: ContractsMonitoring,
        },
        {
          path: "/operations",
          name: "operations",
          meta: {
            label: "Операції",
            requiresAuth: true,
          },
          component: OperationList,
        },
        {
          path: "/declined-payments",
          meta: {
            label: "Ліміти та контроль",
            requiresAuth: true,
          },
          component: DeclinedPaymentList,
        },
        {
          path: "/reporting",
          meta: {
            label: "Статзвітність",
            requiresAuth: true,
          },
          component: Report,
        },
        {
          path: "/regulations",
          meta: {
            label: "Нормативи НБУ",
            requiresAuth: true,
          },
          component: RegulationsNbu,
        },
        {
          path: "/v2/reyestry",
          name: "Reyestry",
          meta: {
            label: "Реєстри",
            requiresAuth: true,
          },
        },
        {
          path: "/entity/financial-models",
          meta: {
            label: "Профілі СПФМ",
            requiresAuth: true,
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              component: FinancialModelList,
            },
            {
              name: "financial-model",
              meta: {
                label: "Профіль СПФМ",
                requiresAuth: true,
              },
              path: ":id",
              component: FinancialModel,
            },
          ],
        },
        {
          path: "/entity/contract",
          meta: {
            label: "Договори",
            requiresAuth: true,
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "ContractList",
              component: ContractList,
            },
            {
              name: "contract",
              meta: {
                label: "Договір",
                requiresAuth: true,
              },
              path: ":id",
              component: Contract,
            },
          ],
        },
        {
          path: "/entity/users",
          meta: {
            label: "Користувачі",
            requiresAuth: true,
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              component: UserTabs,
            },
          ],
        },
        {
          path: "/entity/tasks",
          meta: {
            label: "Дії над контрагентами",
            requiresAuth: true,
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              name: "TasksList",
              path: "",
              component: TasksList,
            },
          ],
        },
        {
          path: "/entity/tasks/create",
          meta: {
            label: "Дії над контрагентами",
            requiresAuth: true,
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              name: "CounterpartActionsList",
              path: "/entity/tasks/create",
              component: CounterpartActionsList,
            },
          ],
        },
      ],
    },
    {
      path: "/sei-pidtverdzhennya",
      meta: {
        label: "ІСЕІ дякую",
        guest: true,
      },
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "finexpress",
          component: Finexpress,
        },
        {
          path: "evropeyska-platizhna-systema",
          component: Eps,
        },
        {
          path: "yedynyy-prostir",
          component: YedynyyProstir,
        },
        {
          path: "platizhnyytsentr",
          component: PlatizhnyyTsentr,
        },
        {
          path: "finexpress/dyakuyu",
          component: DyakuyuFinexpress,
        },
        {
          path: "evropeyska-platizhna-systema/dyakuyu",
          component: DyakuyuEps,
        },
        {
          path: "yedynyy-prostir/dyakuyu",
          component: DyakuyuYedynnyProstir,
        },
        {
          path: "platizhnyytsentr/dyakuyu",
          component: DyakuyuPlatizhnyyTsentr,
        },
      ],
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        guest: true,
      },
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: {
        guest: true,
      },
    },
    {
      path: "/logout",
      name: "Logout",
      component: Logout,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/password/forgot",
      name: "ForgotPassword",
      component: ForgotPassword,
      meta: {
        guest: true,
      },
    },
    {
      path: "/password/reset",
      name: "ResetPassword",
      component: ResetPassword,
      meta: {
        guest: true,
      },
    },
    {
      path: "/password/reset-expired",
      name: "ResetExpiredPassword",
      component: ResetExpiredPassword,
      meta: {
        guest: true,
      },
    },
    {
      path: "/password/set",
      name: "SetPassword",
      component: SetPassword,
      meta: {
        guest: true,
      },
    },
    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["auth/isAuth"]) {
      next({
        name: "Login",
        query: { redirect: window.location.pathname + window.location.search },
      });
    } else {
      if (to.name === "Reyestry") window.location.href = to.path;
      else next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (!store.getters["auth/isAuth"]) {
      next();
    } else {
      next({ name: "Dashboard" });
    }
  } else {
    next();
  }
});

router.beforeResolve((to, from, next) => {
  NProgress.start();
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
