import { axiosDocsInstance } from "@/api/docsApi";

export default {
  databaseDump() {
    return axiosDocsInstance.post("database-dump");
  },
  createFinancialModel(item) {
    return axiosDocsInstance.post("financial-models", item).then((response) => {
      return response.data;
    });
  },
  getFinancialModelById(id) {
    return axiosDocsInstance.get("financial-models/" + id).then((response) => {
      return response.data;
    });
  },
  updateFinancialModel(id, item) {
    return axiosDocsInstance
      .put(`financial-models/${id}`, item)
      .then((response) => {
        return response.data;
      });
  },
  deleteFinancialModel(id) {
    return axiosDocsInstance.delete(`financial-models/${id}`);
  },
  getF2hxsByFinancialModelId(id) {
    return axiosDocsInstance
      .get("financial-models/" + id + "/f2hxs")
      .then((response) => {
        return response.data;
      });
  },
  createAccount(id, form) {
    return axiosDocsInstance
      .post(`financial-models/${id}/account`, form)
      .then((response) => {
        return response.data;
      });
  },
  updateAccount(id, form) {
    return axiosDocsInstance
      .put(`financial-models/account/${id}`, form)
      .then(({ data }) => {
        return data;
      });
  },
  deleteAccount(id) {
    return axiosDocsInstance
      .delete(`financial-models/account/${id}`)
      .then(({ data }) => {
        return data;
      });
  },
  uploadStamp(id, form) {
    return axiosDocsInstance
      .post(`financial-models/${id}/stamps`, form)
      .then((response) => {
        return response.data;
      });
  },
  getStamps(id) {
    return axiosDocsInstance
      .get(`financial-models/${id}/stamps`)
      .then((response) => {
        return response.data;
      });
  },
  getStamp(id) {
    return axiosDocsInstance
      .get(`stamps/${id}`, {
        responseType: "blob",
      })
      .then((response) => {
        return response;
      });
  },
  createLicense(id, form) {
    return axiosDocsInstance
      .post(`financial-models/${id}/license`, form)
      .then((response) => {
        return response.data;
      });
  },
  updateLicense(id, form) {
    return axiosDocsInstance
      .put(`financial-models/license/${id}`, form)
      .then(({ data }) => {
        return data;
      });
  },
  deleteLicense(id) {
    return axiosDocsInstance
      .delete(`financial-models/license/${id}`)
      .then(({ data }) => {
        return data;
      });
  },
  createIsei(id, form) {
    return axiosDocsInstance
      .post(`financial-models/${id}/isei-credentials`, form)
      .then((response) => {
        return response;
      });
  },
  getIseis(id) {
    return axiosDocsInstance
      .get(`financial-models/${id}/isei-credentials`)
      .then((response) => {
        return response.data;
      });
  },
};
