import storage from "@/api/api";
import Vue from "vue";

export const state = {
  counterpart: {}
};

export const getters = {
  get: state => state.counterpart,
  getProfileMaintain: function(state) {
    if (state.counterpart && state.counterpart.profile_maintain)
      return state.counterpart.profile_maintain;
    return {
      asset_freeze_date: null,
      asset_unfreeze_date: null,
      clarification_date: null,
      clarification_task: null,
      completion_date: null,
      establishing_relations_date: null,
      id: null,
      identification_date: null,
      identification_status: null,
      responsible_person: null,
      staging_date: null,
      stop_relations_comment: null,
      stop_relations_date: null,
      video_verification_date: null,
      video_verification_method: null,
      ending_relations_date: null
    };
  },
  getActivityEvaluations: state =>
    state.counterpart ? state.counterpart.activity_evaluations : [],
  getStateEvaluations: state =>
    state.counterpart ? state.counterpart.state_evaluations : [],
  getRiskManagement: state =>
    state.counterpart ? state.counterpart.risk_management : [],
  getLicenses: state => {
    if (!state.counterpart || !state.counterpart.risk_management) return [];
    return state.counterpart.risk_management.licenses;
  },
  getCrimeRisks: state => {
    if (!state.counterpart || !state.counterpart.risk_management) return [];
    return state.counterpart.risk_management.crime_risks;
  },
  getFinancialAssessments: state =>
    state.counterpart ? state.counterpart.financial_assessments : [],
  getClarification: state =>
    state.counterpart ? state.counterpart.clarification : [],
  getPropertyCommanders: state => {
    if (!state.counterpart || !state.counterpart.clarification) return [];
    return state.counterpart.clarification.property_commanders;
  },
  getRepresentatives: state => {
    if (!state.counterpart || !state.counterpart.clarification) return [];
    return state.counterpart.clarification.representatives;
  },
  getSeparateUnits: state => {
    if (!state.counterpart || !state.counterpart.clarification) return [];
    return state.counterpart.clarification.separate_units;
  },
  getParentCompanies: state => {
    if (!state.counterpart || !state.counterpart.clarification) return [];
    return state.counterpart.clarification.parent_companies;
  },
  getOwners: state => {
    if (!state.counterpart || !state.counterpart.clarification) return [];
    return state.counterpart.clarification.owners;
  },
  getBeneficialOwners: state => {
    if (!state.counterpart || !state.counterpart.clarification) return [];
    return state.counterpart.clarification.beneficial_owners;
  },
  getDocument: state => {
    if (!state.counterpart || !state.counterpart.document) return [];
    return state.counterpart.document;
  },
  getHistory: state => (state.counterpart ? state.counterpart.histories : [])
};

export const actions = {
  async pull({ commit }, id) {
    return await storage.getCounterpartById(id).then(({ data }) => {
      commit("push", data);
    });
  },
  async update({ commit }, form) {
    await storage
      .updateCounterpart(form.id, form)
      .then(({ data }) => {
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error("Виникла помилка при оновленні контрагента");
      });
  },
  async createDocument({ commit }, form) {
    await storage
      .createCounterpartDocument(form)
      .then(({ data }) => {
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при створені документа контрагента"
        );
      });
  },
  async createRepresentativeDocument({ commit }, form) {
    await storage
      .createRepresentativeDocument(form)
      .then(({ data }) => {
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при створені документа контрагента"
        );
      });
  },
  async createPropertyCommanderDocument({ commit }, form) {
    await storage
      .createPropertyCommanderDocument(form)
      .then(({ data }) => {
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при створені документа контрагента"
        );
      });
  },
  async updateDocument({ commit }, form) {
    await storage
      .updateCounterpartDocument(form.id, form)
      .then(({ data }) => {
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при оновленні документа контрагента"
        );
      });
  },
  async deleteDocument({ commit }, id) {
    await storage
      .deleteCounterpartDocument(id)
      .then(({ data }) => {
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при видаленні документа контрагента"
        );
      });
  },
  // eslint-disable-next-line no-unused-vars
  async updateProfileMaintain({ commit }, form) {
    await storage
      .updateProfileMaintain(form.id, form)
      .then(() => {
        this._vm.$snotify.success("Оновлено контрагента");
      })
      .catch(() => {
        this._vm.$snotify.error("Виникла помилка при оновленні контрагента");
      });
  },
  async createActivityEvaluation({ commit }, form) {
    await storage
      .createActivityEvaluation(form.counterpart_id, form)
      .then(({ data }) => {
        this._vm.$snotify.success("Додано оцінку відповідності");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при додаванні оцінки відповідності"
        );
      });
  },
  async updateActivityEvaluation({ commit }, form) {
    await storage
      .updateActivityEvaluation(form.id, form)
      .then(({ data }) => {
        this._vm.$snotify.success("Оновлено оцінку відповідності");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при оновленні оцінки відповідності"
        );
      });
  },
  async deleteActivityEvaluation({ commit }, form) {
    await storage
      .deleteActivityEvaluation(form.id)
      .then(({ data }) => {
        this._vm.$snotify.success("Видалено оцінку відповідності");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при видаленні оцінки відповідності"
        );
      });
  },
  async createStateEvaluation({ commit }, form) {
    await storage
      .createStateEvaluation(form.counterpart_id, form)
      .then(({ data }) => {
        this._vm.$snotify.success("Додано оцінку відповідності");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при додаванні оцінки відповідності"
        );
      });
  },
  async updateStateEvaluation({ commit }, form) {
    await storage
      .updateStateEvaluation(form.id, form)
      .then(({ data }) => {
        this._vm.$snotify.success("Оновлено оцінку відповідності");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при оновленні оцінки відповідності"
        );
      });
  },
  async deleteStateEvaluation({ commit }, form) {
    await storage
      .deleteStateEvaluation(form.id)
      .then(({ data }) => {
        this._vm.$snotify.success("Видалено оцінку відповідності");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при видаленні оцінки відповідності"
        );
      });
  },
  async updateRiskManagement({ commit }, payload) {
    const id = payload.counterpart_id;
    delete payload.counterpart_id;
    await storage
      .updateRiskManagement(id, payload)
      .then(({ data }) => {
        this._vm.$snotify.success("Оновлено управління ризиками");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при оновленні управління ризиками"
        );
      });
  },
  async createLicense({ commit }, payload) {
    await storage
      .createCounterpartLicense(payload.counterpart_id, payload)
      .then(({ data }) => {
        this._vm.$snotify.success("Додано ліцензію");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error("Виникла помилка при додаванні ліцензії");
      });
  },
  async updateLicense({ commit }, form) {
    await storage
      .updateCounterpartLicense(form.id, form)
      .then(({ data }) => {
        this._vm.$snotify.success("Оновлено ліцензію");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error("Виникла помилка при оновленні ліцензії");
      });
  },
  async deleteLicense({ commit }, form) {
    await storage
      .deleteCounterpartLicense(form.id)
      .then(({ data }) => {
        this._vm.$snotify.success("Видалено ліцензію");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error("Виникла помилка при видаленні ліцензії");
      });
  },
  async createCrimeRisk({ commit }, payload) {
    await storage
      .createCrimeRisk(payload.counterpart_id, payload)
      .then(({ data }) => {
        this._vm.$snotify.success("Додано рівень ризику");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error("Виникла помилка при додаванні рівня ризику");
      });
  },
  async createFinancialAssessment({ commit }, payload) {
    await storage
      .createFinancialAssessment(payload.counterpart_id, payload)
      .then(({ data }) => {
        this._vm.$snotify.success("Додано нову оцінку фінансового стану");
        commit("push", data);
      });
  },
  async deleteFinancialAssessment({ commit }, form) {
    await storage
      .deleteFinancialAssessment(form.id)
      .then(({ data }) => {
        this._vm.$snotify.success(
          "Видалено результат оцінки фінансового стану"
        );
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при видаленні результату оцінки фінансового стану"
        );
      });
  },
  async updateCrimeRisk({ commit }, form) {
    await storage
      .updateCrimeRisk(form.id, form)
      .then(({ data }) => {
        this._vm.$snotify.success("Оновлено рівень ризику");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error("Виникла помилка при оновленні рівня ризику");
      });
  },
  async deleteCrimeRisk({ commit }, form) {
    await storage
      .deleteCrimeRisk(form.id)
      .then(({ data }) => {
        this._vm.$snotify.success("Видалено рівень ризику");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error("Виникла помилка при видаленні рівня ризику");
      });
  },
  async updateClarification({ commit }, payload) {
    const id = payload.counterpart_id;
    delete payload.counterpart_id;
    await storage
      .updateClarification(id, payload)
      .then(({ data }) => {
        this._vm.$snotify.success("Оновлено вивчення клієнта");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при оновленні вивчення клієнта"
        );
      });
  },
  async createPropertyCommander({ commit }, payload) {
    await storage
      .createPropertyCommander(payload.counterpart_id, payload)
      .then(({ data }) => {
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при додаванні ідентифікаційних данних"
        );
      });
  },
  async updatePropertyCommander({ commit }, form) {
    await storage
      .updatePropertyCommander(form.id, form)
      .then(({ data }) => {
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при оновленні ідентифікаційних данних"
        );
      });
  },
  async deletePropertyCommander({ commit }, form) {
    await storage
      .deletePropertyCommander(form.id)
      .then(({ data }) => {
        this._vm.$snotify.success("Видалено ідентифікаційні дані");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при видаленні ідентифікаційних данних"
        );
      });
  },
  async createRepresentative({ commit }, payload) {
    await storage
      .createRepresentative(payload.counterpart_id, payload)
      .then(({ data }) => {
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при додаванні ідентифікаційних данних"
        );
      });
  },
  async updateRepresentative({ commit }, form) {
    await storage
      .updateRepresentative(form.id, form)
      .then(({ data }) => {
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при оновленні ідентифікаційних данних"
        );
      });
  },
  async deleteRepresentative({ commit }, form) {
    await storage
      .deleteRepresentative(form.id)
      .then(({ data }) => {
        this._vm.$snotify.success("Видалено ідентифікаційні дані");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при видаленні ідентифікаційних данних"
        );
      });
  },
  async createSeparateUnit({ commit }, payload) {
    await storage
      .createSeparateUnit(payload.counterpart_id, payload)
      .then(({ data }) => {
        this._vm.$snotify.success("Додано відокремленний підрозділ");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при додаванні відокремленного підрозділу"
        );
      });
  },
  async updateSeparateUnit({ commit }, form) {
    await storage
      .updateSeparateUnit(form.id, form)
      .then(({ data }) => {
        this._vm.$snotify.success("Оновлено відокремленний підрозділ");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при оновленні відокремленного підрозділу"
        );
      });
  },
  async deleteSeparateUnit({ commit }, form) {
    await storage
      .deleteSeparateUnit(form.id)
      .then(({ data }) => {
        this._vm.$snotify.success("Видалено відокремленний підрозділ");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при видаленні відокремленного підрозділу"
        );
      });
  },
  async createParentCompany({ commit }, payload) {
    await storage
      .createParentCompany(payload.counterpart_id, payload)
      .then(({ data }) => {
        this._vm.$snotify.success("Додано материнську компанію");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при додаванні материнської компанії"
        );
      });
  },
  async updateParentCompany({ commit }, form) {
    await storage
      .updateParentCompany(form.id, form)
      .then(({ data }) => {
        this._vm.$snotify.success("Оновлено материнську компанію");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при оновленні материнської компанії"
        );
      });
  },
  async deleteParentCompany({ commit }, form) {
    await storage
      .deleteParentCompany(form.id)
      .then(({ data }) => {
        this._vm.$snotify.success("Видалено материнську компанію");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при видаленні материнської компанії"
        );
      });
  },
  async deleteAllSeparateUnits({ commit }, counterpartId) {
    await storage
      .deleteSeparateUnits(counterpartId)
      .then(({ data }) => {
        this._vm.$snotify.success("Видалено всі відокремлені підрозділи");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при видаленні всіх відокремленних підрозділів"
        );
      });
  },
  async deleteAllParentCompanies({ commit }, counterpartId) {
    await storage
      .deleteParentCompanies(counterpartId)
      .then(({ data }) => {
        this._vm.$snotify.success("Видалено всі материнські компанії");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при видаленні всіх материнських компаній"
        );
      });
  },
  async createOwner({ commit }, payload) {
    await storage
      .createOwner(payload.counterpart_id, payload)
      .then(({ data }) => {
        this._vm.$snotify.success("Додано пряме володіння");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при додаванні прямого володіння"
        );
      });
  },
  async updateOwner({ commit }, form) {
    await storage
      .updateOwner(form.id, form)
      .then(({ data }) => {
        this._vm.$snotify.success("Оновлено пряме володіння");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при оновленні прямого володіння"
        );
      });
  },
  async deleteOwner({ commit }, form) {
    await storage
      .deleteOwner(form.id)
      .then(({ data }) => {
        this._vm.$snotify.success("Видалено пряме володіння");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при видаленні прямого володіння"
        );
      });
  },
  async createBeneficialOwner({ commit }, payload) {
    await storage
      .createBeneficialOwner(payload.counterpart_id, payload)
      .then(({ data }) => {
        this._vm.$snotify.success("Додано дані про фізичних осіб");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при додаванні данних про фізичних осіб"
        );
      });
  },
  async updateBeneficialOwner({ commit }, form) {
    await storage
      .updateBeneficialOwner(form.id, form)
      .then(({ data }) => {
        this._vm.$snotify.success("Оновлено дані про фізичних осіб");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при оновленні данних про фізичних осіб"
        );
      });
  },
  async deleteBeneficialOwner({ commit }, form) {
    await storage
      .deleteBeneficialOwner(form.id)
      .then(({ data }) => {
        this._vm.$snotify.success("Видалено дані про фізичних осіб");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при видаленні данних про фізичних осіб"
        );
      });
  },
  async flush({ commit }) {
    await commit("clear");
  }
};

export const mutations = {
  push: (state, item) =>
    Object.entries(item).forEach(([key, value]) =>
      Vue.set(state.counterpart, key, value)
    ),
  updateField: (state, { key, value }) =>
    Vue.set(state.counterpart, key, value),
  updateProfileMaintainField: (state, { key, value }) =>
    Vue.set(state.counterpart.profile_maintain, key, value),
  updateRiskManagementField: (state, { key, value }) =>
    Vue.set(state.counterpart.risk_management, key, value),
  updateClarificationField: (state, { key, value }) =>
    Vue.set(state.counterpart.clarification, key, value),
  updateDocumentField: (state, { key, value }) => {
    Vue.set(state.counterpart, "document", {
      ...state.counterpart.document,
      [key]: value
    });
  },
  clear: state => Vue.set(state, "counterpart", {})
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
